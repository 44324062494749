import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import posthog from 'posthog-js';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import persistStore from 'redux-persist/lib/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import './styles/index.scss';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import AppRoutes from './app/app.routes';
import { theme } from './material-theme';
import { store } from './store/create-store';
import ENVIRONMENTS from './environments';
import AlertProvider from './common/providers/alert.provider';
import UserDataProvider from './common/contexts/user-data.context';
import NotificationProvider from './common/contexts/notification-data.context';
import AppDataProvider from './common/contexts/app-data.context';

// eslint-disable-next-line import/no-extraneous-dependencies, @typescript-eslint/no-var-requires
const { stopReportingRuntimeErrors } = require('react-error-overlay');

const queryClient = new QueryClient();

try {
  if (process?.env?.NODE_ENV === 'development') {
    stopReportingRuntimeErrors(); // disables error overlays
  } else {
    posthog.init(ENVIRONMENTS.POSTHOG_API_KEY, {
      api_host: ENVIRONMENTS.POSTHOG_API_HOST,
      debug: true,
    });
  }
} catch (e) {
  console.log(e);
}

ReactDOM.render(
  // <Provider store={store}>
  //   <PersistGate loading={null} persistor={persistStore(store)}>
  //     <React.StrictMode>
  //       <StyledEngineProvider injectFirst>
  //         <QueryClientProvider client={queryClient}>
  //           <ThemeProvider theme={theme}>
  //             <AlertProvider>
  //               <BrowserRouter>
  //                 <AppDataProvider>
  //                   <UserDataProvider>
  //                     <NotificationProvider>
  //                       <AppRoutes />
  //                     </NotificationProvider>
  //                   </UserDataProvider>
  //                 </AppDataProvider>
  //               </BrowserRouter>
  //             </AlertProvider>
  //           </ThemeProvider>
  //         </QueryClientProvider>
  //       </StyledEngineProvider>
  //     </React.StrictMode>
  //   </PersistGate>
  // </Provider>,
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <p> Under Maintenance, We will be back soon.</p>
  </div>,
  document.getElementById('root'),
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(registration => {
        console.log('SW registered: ', registration);
        registration.update();
      })
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}

// Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
